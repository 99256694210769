// == PWD WP Sass Framework ==
// Breadcrumbs navigation styles
// Template: templates/nav-breadcrumbs.php

@import "common/variables";
@import "common/tools";

.nav-breadcrumbs {
	@include pwd-fontsize(18);
	background-color: #F1F1F1;
	line-height: 1.2;
	font-weight: 400;

	li {
		&:not(:last-child) {
			margin-right: 2px;
		}
	}

	a {
		font-weight: 400;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	a,
	.current-item > span,
	[aria-current] {
		display: block;
		padding: 8px 10px;
		color: $theme-color-1;
	}

	a,
	a::before,
	a::after {

	}

	a::after {
		border-right: 3px solid $white;
	}

	.current-item > span,
	[aria-current],
	[aria-current]::before,
	[aria-current]::after {
		color: $theme-color-3;
	}
}

@supports (transform: skewX(-15deg)) {
	.nav-breadcrumbs {
		overflow-x: hidden;
		-ms-overflow-style: none;

		a,
		a::before,
		a::after {
			transition: background-color .35s;
		}

		a:hover,
		a:focus {
			outline: 0;
		}

		a:focus [property="name"] {
			outline: 2px dotted $theme-color-1;
			outline-offset: 2px;
		}

		a:hover,
		a:focus,
		a:hover::before,
		a:hover::after,
		a:focus::before,
		a:focus::after,
		[aria-current],
		[aria-current]::before,
		[aria-current]::after {
			transition: background-color .15s;
		}

		a:hover,
		a:focus,
		a:hover::before,
		a:hover::after,
		a:focus::before,
		a:focus::after {
			background-color: #D1DEE6;
		}

		a,
		[aria-current] {
			position: relative;
			margin-right: 10px;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 14px;
				content: "";
				speak: none;
				transform: skewX(-15deg);
			}

			&::before {
				left: -5px;
			}

			&::after {
				right: -5px;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	// Truncate path in small screen devices
	// and show only 2 last items in list.

	.nav-breadcrumbs__list--truncate {
		&::before {
			display: flex;
			margin-bottom: 4px;
			padding-left: 4px;
			padding-right: 4px;
			align-items: center;
			line-height: 1.2;
			content: "…";
			speak: none;
			background-color: $theme-color-4;
			border-right: 2px solid #ffffff;
		}

		li:not(:nth-last-child(-n+2)) {
			display: none;
		}
	}
}

@include media-breakpoint-up(lg) {
	.nav-breadcrumbs {
		a,
		.current-item > span,
		[aria-current] {
			padding: 16px 15px;
		}
	}

	@supports (transform: skewX(-28deg)) {
		.nav-breadcrumbs {
			a,
			[aria-current] {
				margin-right: 14px;

				&::before,
				&::after {
					width: 14px;
				}

				&::before {
					left: -7px;
				}

				&::after {
					right: -7px;
				}
			}
		}
	}
}
